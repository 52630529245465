.naver-cloud-map {
  width: 80%;
  height: 500px;
  margin: 0 auto;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .naver-cloud-map {
    width: 90%;
    height: 400px;
  }
}
